.flipping {
    width: 86.4px;
    height: 86.4px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
 }
 
 .flipping div {
    animation: flipping-18i5bq 1.7999999999999998s calc(var(--delay) * 1.2s) infinite backwards;
    background-color: #7e76ff;
 }
 
 .flipping div:nth-child(1) {
    --delay: 0.12;
 }
 
 .flipping div:nth-child(2) {
    --delay: 0.24;
 }
 
 .flipping div:nth-child(3) {
    --delay: 0.36;
 }
 
 .flipping div:nth-child(4) {
    --delay: 0.48;
 }
 
 .flipping div:nth-child(5) {
    --delay: 0.6;
 }
 
 .flipping div:nth-child(6) {
    --delay: 0.72;
 }
 
 .flipping div:nth-child(7) {
    --delay: 0.84;
 }
 
 .flipping div:nth-child(8) {
    --delay: 0.96;
 }
 
 .flipping div:nth-child(9) {
    --delay: 1.08;
 }
 
 @keyframes flipping-18i5bq {
    0% {
       transform: perspective(86.4px) rotateX(-90deg);
    }
 
    50%, 75% {
       transform: perspective(86.4px) rotateX(0);
    }
 
    100% {
       opacity: 0;
       transform: perspective(86.4px) rotateX(0);
    }
 }