.date_time {
    display: inline-block;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    float: none;
    z-index: auto;
    width: auto;
    height: auto;
    position: static;
    cursor: default;
    opacity: 1;
    margin: 0;
    padding: 10px 20px;
    overflow: visible;
    border: 1px solid #b7b7b7;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    font: normal 16px/normal "Times New Roman", Times, serif;
    -o-text-overflow: clip;
    text-overflow: clip;
    background: rgba(252, 252, 252, 1);
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2) inset;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2) inset;
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.66);
    -webkit-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
    -moz-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
    -o-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
    transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-transform: none;
    transform: none;
    -webkit-transform-origin: 50% 50% 0;
    transform-origin: 50% 50% 0;
}