#dwv {
  height: 100%;
}

.button-row {
  text-align: center;
}

/* Layers */
.layerGroup {
  position: relative;
  padding: 0;
  display: flex;
  justify-content: center;
  height: 100%;
}

.layerContainer {
  position: relative;
  padding: 0;
  margin: auto;
  text-align: center;
}

.layer {
  position: absolute;
  pointer-events: none;
  /* width: 100%; */
  height: 100%;
}

.layer canvas {
  margin-top: 12px;
  overflow: hidden;
}

.drawDiv {
  position: absolute;
  pointer-events: none;
}

/* drag&drop */
.dropBox {
  margin: auto;
  text-align: center;
  vertical-align: middle;
  width: 50%;
  height: 75%;
}

.dropBoxBorder {
  border: 5px dashed #e8eaf6;
  /*indigo:light*/
}

.dropBoxBorder.hover {
  border: 5px dashed #3f51b5;
  /*indigo*/
}

.dcm-dicom-small-view {
  height: 150px;
  overflow: hidden;
}