@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,500;0,700;0,800;1,500;1,700;1,800;1,900&display=swap');
.xrg-text-12{
    font-size: 0.9rem;
}

.xrg-text-10{
    font-size: 0.8rem;
}

.xrg-text-14{
    font-size: 1rem;
}


.xrg-text-16{
    font-size: 1.1rem;
}
.xrg-fw-300{
    font-weight: 300;
}
.xrg-fw-500{
    font-weight: 500;
}
.xrg-fw-900{
    font-weight: 900;
}

.cursor-pointer{
    cursor: pointer;
}

.App{
    min-height: 100vh;
    min-width: 100vw;
    background-color: #f5f4ff;
    font-family: 'Nunito';
}

.dcm-loader{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    z-index: 10000000;
    position: fixed;
    align-items: center;
    display: flex;
    justify-content: center;

}

.dcm-loader-icon{
    position: fixed;
    text-align:center;
}

.dcm-loader-text{
    text-align: center;
    color: #7e7e7e;
}