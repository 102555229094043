.dcm-dropdown {
    padding: 0.75rem 1rem;
    border-radius: 5px;
    color: #4b4b4b;
    font-size: 0.8rem;  
}

.dcm-dropdown-item{
    margin: 5px 2px;
    font-size: 14px;
}
